import { Component, Suspense } from "react";
import { I18nextProvider } from "react-i18next";
import { Provider as ReduxProvider } from "react-redux";
import { MemoqThemeProvider } from "@memoq/memoq.web.design";
import { ThemeProvider, StyledEngineProvider, createTheme, CssBaseline, Theme } from "@mui/material";
import moment from "moment";
import { PersistGate } from "redux-persist/integration/react";
import { Props } from "./appInterfaces";
import { RouterWrapper } from "bootstrap/app/RouterWrapper";
import { AuthProvider } from "core/authentication/AuthProvider";
import Configuration from "core/configuration";
import i18next from "core/i18n";
import { ErrorBoundary } from "hoc/_common/ErrorBoundary";
import { BrightTheme, DarkTheme } from "theme";

declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

export default class App extends Component<Props, {useDark: boolean}> {
    brightTheme: Theme;
    purpleTheme: Theme;
    constructor(props: Props) {
        super(props);
        this.brightTheme = createTheme(BrightTheme);
        this.purpleTheme = createTheme(DarkTheme);
        this.state = { useDark: true };
    }

    componentDidMount() {
        window.addEventListener("keyup", this.onKeyPress);
    }

    componentWillUnmount() {
        window.removeEventListener("keyup", this.onKeyPress);
    }

    changeTheme() {
        if (!Configuration.isDebug()) {
            this.setState({ useDark: !this.state.useDark });
        }
    }

    changeLanguage() {
        if (!Configuration.isDebug()) {
            const lng = i18next.language === "debug" ? "en" : "debug";
            void i18next.changeLanguage(lng);
            moment.locale(lng);
        }
    }

    onKeyPress = (e: KeyboardEvent) => {
        if (e.ctrlKey) {
            if (e.key.toLowerCase() === "m") {
                this.changeTheme();
            }
            if (e.key.toLowerCase() === "b") {
                this.changeLanguage();
            }
        }
    };

    render() {
        const { store, user, persistor } = this.props;

        return (
            <MemoqThemeProvider>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={this.state.useDark ? this.purpleTheme : this.brightTheme}>
                        <CssBaseline />
                        <I18nextProvider i18n={i18next}>
                            <AuthProvider>
                                <ReduxProvider store={store}>
                                    <PersistGate loading={null} persistor={persistor}>
                                        <Suspense fallback={null}>
                                            <ErrorBoundary>
                                                <RouterWrapper persistor={persistor} user={user} />
                                            </ErrorBoundary>
                                        </Suspense>
                                    </PersistGate>
                                </ReduxProvider>
                            </AuthProvider>
                        </I18nextProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </MemoqThemeProvider>
        );
    }
}
